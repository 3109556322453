import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/index",
        component: () => import("../views/Index.vue"),
      },
      {
        path: "/roles",
        component: () => import("../views/Roles.vue"),
      },
      {
        path: "/permissions",
        component: () => import("../views/Permissions.vue"),
      },
      {
        path: "/admins",
        component: () => import("../views/Admins.vue"),
      },
      {
        path: "/menus",
        component: () => import("../views/Menus.vue"),
      },
      {
        path: "/posts",
        component: () => import("../views/Posts.vue"),
      },
      {
        path: "/dictionaries",
        component: () => import("../views/Dictionaries.vue"),
      },
      {
        path: "/logs",
        component: () => import("../views/Logs.vue"),
      },
      {
        path: "/helps",
        component: () => import("../views/Helps.vue"),
      },
      {
        path: "/terms",
        component: () => import("../views/Terms.vue"),
      },
      {
        path: "/courseType",
        component: () => import("../views/CourseType.vue"),
      },
      {
        path: "/courseCategories",
        component: () => import("../views/CourseCategory.vue"),
      },
      {
        path: "/teachers",
        component: () => import("../views/Teachers.vue"),
      },
      {
        path: "/users",
        component: () => import("../views/Users.vue"),
      },
      {
        path: "/clientRoutes",
        component: () => import("../views/clientRoutes.vue"),
      },
      {
        path: "/adverts",
        component: () => import("../views/Adverts.vue"),
      },
      {
        path: "/courses",
        component: () => import("../views/Course.vue"),
      },
      {
        path: "/owners",
        component: () => import("../views/Owners.vue"),
      },
      {
        path: "/agents",
        component: () => import("../views/Agents.vue"),
      },
      {
        path: "/vips",
        component: () => import("../views/Vips.vue"),
      },
      {
        path: "/discounts",
        component: () => import("../views/Discounts.vue"),
      },
      {
        path: "/courseComment",
        component: () => import("../views/CourseComment.vue"),
      },
      {
        path: "/orders",
        component: () => import("../views/Orders.vue"),
      },
      {
        path: "/merchants",
        component: () => import("../views/Merchants.vue"),
      },
      {
        path: "/notices",
        component: () => import("../views/Notices.vue"),
      },
      {
        path: "/themes",
        component: () => import("../views/Themes.vue"),
      },
      {
        path: "/statistics",
        component: () => import("../views/Statistics.vue"),
        redirect: "/courseStatistic",
        children: [
          {
            path: "/courseStatistic",
            component: () => import("../views/CourseStatistic.vue"),
          },
          {
            path: "/agentStatistic",
            component: () => import("../views/AgentStatistic.vue"),
          },
          {
            path: "/vipStatistic",
            component: () => import("../views/VipStatistic.vue"),
          },
          {
            path: "/userAgentStatistic",
            component: () => import("../views/UserAgentStatistic.vue"),
          },
          {
            path: "/teachersStatistic",
            component: () => import("../views/TeachersStatistic.vue"),
          },
        ],
      },
      {
        path: "/liveRooms",
        component: () => import("../views/liveRooms.vue"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/test",
    component: () => import("../views/Test.vue"),
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  routes,
  linkActiveClass: "active-link",
  mode: "history", //取消路由上的 #
});

export default router;
