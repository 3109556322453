import Vue from 'vue'
import App from './App.vue'

// reset style
import './assets/style/reset.css'

import router from './router'

import './plugins/element.js'

Vue.config.productionTip = true

// ie: "ReferenceError: “Promise”未定义
import "babel-polyfill";

/*封装axios ajax方法*/
import axios from 'axios'
import baseConfig from './base.config.js'

axios.defaults.timeout = 10000;
axios.defaults.baseURL = baseConfig.apiUrl;

// 封装get方法
export function fetch(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {headers:{
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },params: params}).then(response => {
      resolve(response);
    }).catch(err => {
      reject(err)
      if(document.getElementsByClassName('el-message').length == 0){
        if(err.response){
          myVue.$message({
            message: err.response.data.message,
            type: 'warning'
          });
        }else{
          myVue.$message({
            message: "请求超时，请等会再试",
            type: 'warning'
          });
        }
      }
      if(err.response.status == 401){
        toLogin();
      }
    })
  })
}

// 封装post请求
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, {headers:{
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }}).then(response => {
      resolve(response);
    }, err => {
      reject(err)
      myVue.$message({
        message: err.response.data.message,
        type: 'warning'
      });
      if(err.response.status == 401){
        toLogin();
      }
    })
  })
}

// 封装patch请求
export function patch(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data, {headers:{
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }}).then(response => {
      resolve(response);
    }, err => {
      reject(err)
      myVue.$message({
        message: err.response.data.message,
        type: 'warning'
      });
      if(err.response.status == 401){
        toLogin();
      }
    })
  })
}

// 封装put请求
export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data, {headers:{
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }}).then(response => {
      resolve(response);
    }, err => {
      reject(err)
      myVue.$message({
        message: err.response.data.message,
        type: 'warning'
      });
      if(err.response.status == 401){
        toLogin();
      }
    })
  })
}

// 封装delete方法
export function remove(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.delete(url, {headers:{
      'Authorization': 'Bearer ' + localStorage.getItem("token"),
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    },data: data}).then(response => {
      resolve(response);
    }).catch(err => {
      reject(err)
      myVue.$message({
        message: err.response.data.message,
        type: 'warning'
      });
      if(err.response.status == 401){
        toLogin();
      }
    })
  })
}

Vue.prototype.$fetch = fetch;
Vue.prototype.$post = post;
Vue.prototype.$remove = remove;
Vue.prototype.$patch = patch;
Vue.prototype.$put = put;
Vue.prototype.$axios = axios;

// toLogin
function toLogin(){
  setTimeout(function(){
    myVue.$router.push({ path: "/login" });
  },500);
}

var myVue = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
